export default {
  "receive": "Recevoir",
  "mining_pool": "Pool de minage",
  "account": "Compte",
  "pool_data": "Données du pool",
  "total_output": "Production totale",
  "valid_node": "Nœud valide",
  "participant": "Participant",
  "user_revenue": "Revenu de l'utilisateur",
  "activity": "Activité",
  "mining": "Minage",
  "liquidity": "Revenus de minage de liquidité",
  "user_output": "Production de l'utilisateur",
  "address": "Adresse",
  "quantity": "Quantité",
  "help_center": "Centre d'aide",
  "hep_center_tip": "j'espère que cela vous aide",
  "question1": "Comment puis-je participer ?",
  "answer1": "Pour participer au minage de liquidité sans pertes et non garanti, vous devez payer des frais de mineur ETH pour recevoir le voucher, et une adresse de portefeuille ETH ne doit être réclamée qu'une seule fois. Après succès, les autorisations de minage seront ouvertes automatiquement.",
  "question2": "Comment puis-je retirer de l'argent ?",
  "answer2": "Vous pouvez convertir les pièces produites quotidiennement en USDT, puis initier un retrait. Le retrait de USDT sera automatiquement envoyé à l'adresse du portefeuille que vous avez ajoutée au nœud, et d'autres adresses ne sont pas supportées.",
  "question3": "Comment est calculé le revenu ?",
  "answer3": "Lorsque vous rejoignez avec succès, le contrat intelligent commence à calculer votre adresse via le nœud et commence à calculer le revenu.",
  "audit_report": "Rapport d'audit",
  "audit_report_tip": "nous avons un rapport d'audit sécurisé",
  "partner": "Partenaire",
  "partner_tip": "notre partenaire commercial",
  "my_account": "Mon compte",
  "mining_status": "État du minage",
  "wallet_balance": "Solde du portefeuille",
  "exchangeable": "Échangeable",
  "withdrawable": "Retirable",
  "share_dividends": "Dividendes d'actions",
  "my_level": "Mon niveau",
  "level": "Niveau",
  "hash_rate": "Taux de hachage",
  "output_interest": "Intérêt de production",
  "output_interest_rate": "Taux d'intérêt de production",
  "output_interest_cycle": "Cycle d'intérêt de production",
  "level_details": "Détails du niveau",
  "pair_price": "Prix ​​de la paire",
  "exchange": "Échanger",
  "withdraw": "Retirer",
  "redeem_all": "Tout racheter",
  "total_balance": "Solde total",
  "record": "Enregistrer",
  "shared": "Partagé",
  "output": "Production",
  "back": "Retour",
  "no-data": "Pas de données",
  "received": "Reçu",
  "transfer": "Transférer",
  "share_friends": "Partager avec des amis",
  "my_share_link": "Mon lien de partage",
  "copy": "Copier",
  "share_tip": "Envoyez votre lien d'invitation, les amis rejoignent le nœud via votre lien et vous recevrez des récompenses généreuses en jetons",
  "join_tip": "Vous avez rejoint",
  "copySuccess": "Lien copié",
  "apply_mining_pool_rewards": "Demander les récompenses du pool de minage",
  "standard": "Standard",
  "required": "Requis",
  "countdown": "Compte à rebours",
  "host_day": "Jour",
  "reward": "Récompense",
  "i_know": "Je sais",
  "popup_miniing_title": "Demander les récompenses du pool de minage",
  "popup_miniing_conn1": "Contrat intelligent ERC-20",
  "popup_miniing_conn2": "Le pool de minage du contrat intelligent ERC-20 s'apprête à démarrer le prochain événement de partage ETH. Demandez à rejoindre maintenant. Après le début de l'événement, les fournisseurs de liquidité peuvent partager plusieurs récompenses",
  "popup_miniing_btn": "Demander les récompenses",
  "recharge": "Recharge",
  "recharge_currency": "Adresse de dépôt",
  "recharge_scan code transfer": "Enregistrer le QR",
  "recharge_amount": "Montant du dépôt",
  "please enter": "Veuillez entrer",
  "recharge_upload": "Cliquez pour télécharger les images",
  "confirm": "Confirmer",
  "rachange_link_coinbase": "Canal de recharge officiel de Coinbase",
  "rachange_link_binance": "Canal de recharge officiel de Binance",
  "rachange_link_huobi": "Canal de recharge officiel de Huobi",
  "cancel": "Annuler",
  "addressCopySuccess": "L'adresse a été copiée",
  "recharge_record": "Historique des recharges",
  "all": "Tout",
  "processing": "En attente",
  "credited": "Succès",
  "fail": "Échoué",
  "amount": "Montant",
  "state": "Statut",
  "time": "Temps",
  "record_tip": "Remarque",
  "activity-top-title": "Récompenses de largage ETH",
"activity-top-big-title": "1 Million de Récompenses de Largage ETH Arrivent",
"activity-top-tip": "Complétez des tâches simples sur la page du nœud de la piscine minière de votre projet de rendement DeFi pour partager une récompense d'airdrop énorme.",
"activity-end": "Activité Terminée",
"activity-join": "Rejoindre l'Activité",
}