export default {
  "receive": "Al",
  "mining_pool": "Madencilik Havuzu",
  "account": "Hesap",
  "pool_data": "Havuz Verisi",
  "total_output": "Toplam Çıktı",
  "valid_node": "Geçerli Düğüm",
  "participant": "Katılımcı",
  "user_revenue": "Kullanıcı Geliri",
  "activity": "Etkinlik",
  "mining": "Madencilik",
  "liquidity": "Likidite madenciliği geliri",
  "user_output": "Kullanıcı Çıktısı",
  "address": "Adres",
  "quantity": "Miktar",
  "help_center": "Yardım Merkezi",
  "hep_center_tip": "umarım size yardımcı olur",
  "question1": "Nasıl katılabilirim?",
  "answer1": "Hasarsız ve garantisiz likidite madenciliğine katılmak için, makbuz almak üzere bir ETH madenci ücreti ödemeniz gerekir ve bir ETH cüzdan adresinin yalnızca bir kez talep edilmesi gerekir. Başarıdan sonra madencilik izinleri otomatik olarak açılır.",
  "question2": "Parayı nasıl çekebilirim?",
  "answer2": "Günlük üretilen paraları USDT'ye dönüştürebilir ve ardından çekme işlemi başlatabilirsiniz. USDT çekimi, düğüme eklediğiniz cüzdan adresine otomatik olarak gönderilir ve diğer adresler desteklenmez.",
  "question3": "Gelir nasıl hesaplanır?",
  "answer3": "Başarılı bir şekilde katıldığınızda, akıllı sözleşme düğüm üzerinden adresinizi hesaplamaya başlar ve geliri hesaplamaya başlar.",
  "audit_report": "Denetim Raporu",
  "audit_report_tip": "güvenli bir denetim raporumuz var",
  "partner": "Ortak",
  "partner_tip": "iş ortağımız",
  "my_account": "Hesabım",
  "mining_status": "Madencilik Durumu",
  "wallet_balance": "Cüzdan Bakiyesi",
  "exchangeable": "Değiştirilebilir",
  "withdrawable": "Çekilebilir",
  "share_dividends": "Hisse Temettüleri",
  "my_level": "Seviyem",
  "level": "Seviye",
  "hash_rate": "Hash Oranı",
  "output_interest": "Çıktı Faizi",
  "output_interest_rate": "Çıktı Faiz Oranı",
  "output_interest_cycle": "Çıktı Faiz Döngüsü",
  "level_details": "Seviye Detayları",
  "pair_price": "Çift Fiyatı",
  "exchange": "Değişim",
  "withdraw": "Çekim",
  "redeem_all": "Hepsini Çöz",
  "total_balance": "Toplam Bakiye",
  "record": "Kayıt",
  "shared": "Paylaşıldı",
  "output": "Çıktı",
  "back": "Geri",
  "no-data": "Veri Yok",
  "received": "Alındı",
  "transfer": "Transfer",
  "share_friends": "Arkadaşlarla Paylaş",
  "my_share_link": "Paylaşım Bağlantım",
  "copy": "Kopyala",
  "share_tip": "Davet bağlantınızı gönderin, arkadaşlarınız bağlantınız aracılığıyla düğüme katılır ve cömert token ödülleri alırsınız",
  "join_tip": "Katıldınız",
  "copySuccess": "Bağlantı kopyalandı",
  "apply_mining_pool_rewards": "Madencilik Havuzu Ödüllerine Başvur",
  "standard": "Standart",
  "required": "Gerekli",
  "countdown": "Geri Sayım",
  "host_day": "Gün",
  "reward": "Ödül",
  "i_know": "Biliyorum",
  "popup_miniing_title": "Madencilik Havuzu Ödüllerine Başvur",
  "popup_miniing_conn1": "ERC-20 Akıllı Sözleşme",
  "popup_miniing_conn2": "ERC-20 akıllı sözleşme madencilik havuzu bir sonraki ETH paylaşım etkinliğine başlamak üzere. Şimdi katılmak için başvurun. Etkinlik başladığında, likidite sağlayıcılar birden fazla ödül paylaşabilir",
  "popup_miniing_btn": "Ödüllere Başvur",
  "recharge": "Yükleme",
  "recharge_currency": "Mevduat adresi",
  "recharge_scan code transfer": "QR'ı Kaydet",
  "recharge_amount": "Mevduat miktarı",
  "please enter": "Lütfen girin",
  "recharge_upload": "Resimleri yüklemek için tıklayın",
  "confirm": "Onayla",
  "rachange_link_coinbase": "Coinbase'in resmi yükleme kanalı",
  "rachange_link_binance": "Binance'in resmi yükleme kanalı",
  "rachange_link_huobi": "Huobi'nin resmi yükleme kanalı",
  "cancel": "İptal",
  "addressCopySuccess": "Adres kopyalandı",
  "recharge_record": "Yükleme Kaydı",
  "all": "Hepsi",
  "processing": "Beklemede",
  "credited": "Başarılı",
  "fail": "Başarısız",
  "amount": "Miktar",
  "state": "Durum",
  "time": "Zaman",
  "record_tip": "Not",
  "activity-top-title": "ETH Airdrop Ödülleri",
"activity-top-big-title": "1 Milyon ETH Airdrop Ödülü Geliyor",
"activity-top-tip": "DeFi getiri projesinin madencilik havuzu düğüm sayfasında basit görevleri tamamlayarak dev bir hava damlası ödülü paylaşın.",
"activity-end": "Etkinlik Sonlandı",
"activity-join": "Etkinliğe Katıl",
}